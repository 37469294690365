.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999 !important;
}
.new_cat_slider .card-2 {
  @media screen and (max-width: 426px) {
    height: 110px;
    width: 100px;
    min-height: 110px;
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    figure {
      height: 70px;
    }
    img {
      border-radius: 10px;
    }
  }
}
.new_cat_slider .swiper-wrapper {
  @media screen and (max-width: 426px) {
    height: 130px;
  }
}

.myNewArrow .swiper-button-prev,
.myNewArrow .swiper-container-rtl .swiper-button-next {
  left: -16px;
  right: auto;
  @media screen and (max-width: 480px) {
    display: none !important;
  }
}

.myNewArrow .swiper-button-next,
.myNewArrow .swiper-container-rtl .swiper-button-prev {
  right: -16px;
  left: auto;
  @media screen and (max-width: 480px) {
    display: none !important;
  }
}

.myNewArrow .swiper-button-prev,
.myNewArrow .swiper-button-next {
  top: 60%;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0);
  background-color: #fff;
  border: 1px solid gray;
  border-radius: 50%;
  color: blue;
  font-weight: 700;
  outline: 0;
  transition: background-color 0.2s ease, color 0.2s ease;

  &::after {
    font-size: 16px;
  }
}

.myNewArrow .swiper-button-prev {
  &:after {
    position: relative;
    left: -1px;
  }
}

.myNewArrow .swiper-button-next {
  &:after {
    position: relative;
    left: 1px;
  }
}

.myNewArrow .swiper-button-prev,
.myNewArrow .swiper-container-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}

.myNewArrow .swiper-button-next,
.myNewArrow .swiper-container-rtl .swiper-button-prev {
  right: 10px;
  left: auto;
}

.myNewArrow .swiper-button-prev.swiper-button-disabled,
.myNewArrow .swiper-button-next.swiper-button-disabled {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

/* flex */
.flex-col {
  flex-direction: column !important;
}

.justify-center {
  justify-content: center !important;
}
.justify-end {
  justify-content: end !important;
}
.justify-between {
  justify-content: space-between !important;
}

.flex-1 {
  flex: 1 !important;
}
.sm\:align-items-end {
  @media screen and (max-width: 480px) {
    align-items: flex-end !important;
  }
}

///////@at-root
.custom_dot_line {
  transform: rotate(90deg);
  height: 32px;
  width: 100px;
}
.dot_wrapper {
  height: 100px;
  width: 32px;
}
.dot_wrapper svg {
  height: 100px;
  width: 32px;
  margin-left: -12px;
  margin-top: 5px;
}
.otp_input {
  height: 100%;
  width: 100% !important;
  border: #adb5bd solid;
  border-radius: 5px;
}

.path-vert {
  stroke-dasharray: 10;
  /*   stroke-dashoffset: 800; */
  // animation: draw1 5s linear alternate;
}

@keyframes draw1 {
  from {
    stroke-dashoffset: 1200;
  }
  to {
    stroke-dashoffset: 600;
  }
}
.otp_container {
  width: 55%;
}
.otp_container div {
  width: 40px;
  height: 40px;
}
.otp_container.otp_cont_2 div {
  width: 75px;
  height: 40px;
  margin: 0px 5px 0 0;
}

.otp_container input {
  padding: 0 !important;
}
.otp_focus {
  border: rgb(0, 0, 0) solid !important;
  // @media screen and (max-width:768px) {
  // border:none !important;
  // }
}
.custom_location {
  background-color: #c5ead9 !important;
  border-radius: 10px;
}
.custom_location a {
  background-color: #c5ead9 !important;
  height: 44px;
  padding: 10px !important;
}

.custom_location a img {
  margin-right: 10px !important;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.g_marker {
  position: absolute;
  width: 38px;
  height: 37px;
  background-image: url(https://icon-library.com/images/pin-icon-png/pin-icon-png-9.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: grab;
}
.g_autoComplete {
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  text-align: center;
}
.g_googlemap {
  width: 100%;
  height: 100%;
}
.main-map-wrapper {
  height: 100vh;
  width: 100%;
  margin-bottom: 4rem;
  filter: drop-shadow(-1px 5px 3px #ccc);
}

.info-wrapper {
  margin-top: 15px;
}
.map-details {
  text-align: center;
  font-size: 1.2em;
}
.map-details span {
  font-weight: bold;
}
.search-input {
  font-size: 1.2em;
  width: 80%;
}
.search_map {
  position: absolute;
  top: 10px;
  left: 10px;
  // transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 10;
}
.search_map input {
  padding: 0 15px;
  font-size: 1rem;
  width: 80%;
  background: #fff;
  height: 40px;
  border-radius: 5px;
}
.locate_map {
  position: absolute;
  top: 4rem;
  right: 9px;
  z-index: 10;
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 5px;
}
.searched_list {
  padding: 1rem;
  padding-top: 5px;
  background: white;
  width: 80%;
  border-radius: 0 0 10px 10px;
  margin-top: 1px;
  z-index: 999;
}
.display-sm-block {
  display: none;
  @media screen and (max-width: 820px) {
    display: block;
  }
}
.display-sm-none {
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.display-lg-none {
  display: none !important;
  @media screen and (max-width: 1200px) {
    display: block !important;
  }
}
.display-md-none {
  display: block;
  @media screen and (max-width: 1200px) {
    display: none;
  }
}
.positioning {
  top: 11px;
  right: 10px;
}

.list_cat {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.custom-filter-bar {
  @media screen and (max-width: 1200px) {
    position: absolute;
    background: white;
    max-width: 30%;
    min-width: 300px;
    left: 0;
    top: 0;
    padding: 2rem;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
    z-index: 99;
    display: block !important;
    max-height: 100vh;
    height: 100vh;
    overflow-y: scroll;
  }
}

.background-cover {
  display: none;
  @media screen and (max-width: 1200px) {
    display: block;
    background-color: black;
    opacity: 0.3;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
}
.bg-lightGray {
  background-color: #f4f4f4;
}
.shoplist-child {
  // min-height: 345px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 350px;
}
.shoplist {
  width: 100%;
}

.price-per {
  font-size: 14px !important;
  color: #2e9d5e !important;
  font-weight: 500;
}
.form-check-input:checked {
  background-color: #1b7834 !important;
  border-color: #8aa598 !important;
}
.display-md-block {
  display: none;
  @media screen and (max-width: 1200px) {
    display: block;
  }
}
.sm\:flex-col {
  @media screen and (max-width: 480px) {
    flex-direction: column !important;
  }
}
.md\:flex-col {
  @media screen and (max-width: 768px) {
    flex-direction: column !important;
  }
}
.sm\:mt-1 {
  @media screen and (max-width: 480px) {
    margin-top: 1rem !important;
  }
}
.sm\:display-between {
  @media screen and (max-width: 480px) {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
}
.sm\:m-0 {
  @media screen and (max-width: 480px) {
    margin: 0 !important;
  }
}
.sm\:p-0 {
  @media screen and (max-width: 480px) {
    padding: 0px !important;
  }
}
.sm\:p-1 {
  @media screen and (max-width: 480px) {
    padding: 10px !important;
  }
}
.sm\:px-0 {
  @media screen and (max-width: 480px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.sm\:pt-2 {
  @media screen and (max-width: 480px) {
    padding-top: 1.5rem !important;
  }
}
.sm\:p-2 {
  @media screen and (max-width: 480px) {
    padding: 1.5rem !important;
  }
}
.sm\:p-3 {
  @media screen and (max-width: 480px) {
    padding: 2rem !important;
  }
}
.sm\:d-flex {
  @media screen and (max-width: 480px) {
    display: flex !important;
  }
}
.sm\:flex-col {
  @media screen and (max-width: 480px) {
    flex-direction: column !important;
  }
}
.cart_total_label {
  @media screen and (max-width: 480px) {
    text-align: start !important;
  }
}
.sm\:w-fit {
  @media screen and (max-width: 480px) {
    width: fit-content !important;
  }
}
.install_app_container {
  @media screen and (max-width: 1200px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.col-xs-6 {
  @media screen and (max-width: 480px) {
    width: 50% !important;
  }
}
.mob_detail_extralink .detail-qty {
  padding: 5px 7px 5px 14px !important;
  max-width: 77px !important;
  @media screen and (max-width: 480px) {
    // padding: 5px !important;
    // max-width: 100% !important;
    padding: 5px 45px 5px 20px !important;
    margin-bottom: 5px !important;
  }
}
.custom_collection_sidebar .image {
  height: 40px !important;
  width: 40px !important;
}
.delete-modal {
  .modal-content {
    background-clip: unset;
  }
}
.font-size-13 {
  font-size: 13px !important;
}
.font-size-14 {
  font-size: 14px !important;
}

.modal-scroll-div {
  max-height: 630px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #95a5a6;
    border: 1px solid #cacaca;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: var(--primary-colors);
  }
}
